import {
	giftReducer,
	orderInformationReducer,
	taglineReducer,
	paymentCardsReducer
} from './reducers';

import { configureStore } from '@reduxjs/toolkit';

import type { ThunkAction, Action } from '@reduxjs/toolkit';

export const store = configureStore({
	reducer: {
		gift: giftReducer,
		orderInformation: orderInformationReducer,
		tagline: taglineReducer,
		paymentCards: paymentCardsReducer
	},
});

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof store.getState>;
export type TAppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	TRootState,
	unknown,
	Action<string>
>;
