import { SuccessPayment } from '../../components';
import { useAppSelector } from '../../redux/hooks';

export const SuccessPaymentContainer = () => {
	const selectedPaymentCard = useAppSelector(state => state.paymentCards.selectedPaymentCard);
	const selectedTagline = useAppSelector(state => state.tagline.selectedTagline);

	return (
		<SuccessPayment
			selectedPaymentCard={selectedPaymentCard}
			selectedTagline={selectedTagline?.label}
		/>
	);
};
