import styles from './Footer.module.scss';

import {
	Twitter,
	Facebook,
	Instagram,
	Spotify,
	Medium,
} from '../../shared/SocialIcon';

import cn from 'classnames';

export const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<footer className={cn('Content__Bg Content__Bg_black', styles.Footer)}>
			<div className={styles.Footer__SocialLinks}>
				<a
					href="http://instagram.com/shred"
					target="_blank"
					rel="noreferrer"
				>
					<Instagram className={styles.Footer__SocialLinkIcon} />
				</a>

				<a
					href="https://twitter.com/shredapp"
					target="_blank"
					rel="noreferrer"
				>
					<Twitter className={styles.Footer__SocialLinkIcon} />
				</a>

				<a
					href="http://facebook.com/shredlabs"
					target="_blank"
					rel="noreferrer"
				>
					<Facebook className={styles.Footer__SocialLinkIcon} />
				</a>

				<a
					href="https://open.spotify.com/user/owj3uddvk94w3eid4drlh7e3u?si=m1HqK6gpTlCxxbVqwZVbAw"
					target="_blank"
					rel="noreferrer"
				>
					<Spotify className={styles.Footer__SocialLinkIcon} />
				</a>

				<a
					href="https://shredapp.medium.com"
					target="_blank"
					rel="noreferrer"
				>
					<Medium className={styles.Footer__SocialLinkIcon} />
				</a>
			</div>

			<span>&copy; {year} Shred Labs, LLC</span>
		</footer>
	);
};
