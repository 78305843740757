import styles from './TextArea.module.scss';

import type { TextareaHTMLAttributes } from 'react';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string;
	description?: string;
}

export const TextArea = ({ className, description, ...rest }: IProps) => {
	return (
		<div className={className}>
			<textarea
				cols={30}
				rows={10}
				maxLength={150}
				className={styles.TextArea}
				{...rest}
			/>

			{description ? (
				<span className={styles.TextArea__Description}>
					{description}
				</span>
			) : null}
		</div>
	);
};
