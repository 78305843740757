import styles from './Modal.module.scss';

export const Modal = ({ show, title, preTitle }: IProps) => (show ? (
	<section className={styles.bg}>
		<div className={styles.container}>
			<span className={styles.title}>{title}</span>
			<span className={styles.preTitle}>{preTitle}</span>
		</div>
	</section>
) : null);

interface IProps {
    show: boolean;
    title: string;
    preTitle: string;
}
