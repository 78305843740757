import styles from './OrderPreview.module.scss';

import {
	Title,
	FieldView,
	TextArea,
	Button,
	PaymentCard,
	Envelope
} from '../../shared';

import cn from 'classnames';
import { useState } from 'react';

import type { ComponentProps, ChangeEvent } from 'react';

interface IProps {
	orderInformation: IOrderInformation | null;
	disableSubmitButton: boolean;
	selectedPaymentCard: TPaymentCardProps['card'] | null;
	selectedTagline?: string;
	submitErrorsList: string[] | null;
	onSubmit: (message: string) => void;
}

const textAreaMaxLength = 150;

export const OrderPreview = ({
	orderInformation,
	selectedTagline,
	selectedPaymentCard,
	disableSubmitButton,
	submitErrorsList,
	onSubmit
}: IProps) => {
	const [message, setMessage] = useState('');

	const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setMessage(event.target.value);
	};

	const onSubmitButtonClick = () => {
		onSubmit(message);
	};

	return (
		<section>
			<Title className={styles.OrderPreview__Title}>
				<span>
					4.
				</span>
				{' '} Preview your card order
			</Title>

			<div className={cn('Content__Bg Content__Bg_gray', styles.OrderPreview__Content)}>
				<div className={styles.OrderPreview__Block}>
					<Envelope className={styles.OrderPreview__Envelope}>
						{selectedPaymentCard && (
							<PaymentCard card={selectedPaymentCard} />
						)}
					</Envelope>
				</div>

				<div className={styles.OrderPreview__Block}>
					<div>
						<FieldView
							label='To'
							value={orderInformation?.to}
							valueDescription={orderInformation?.recipientEmail}
							className={styles.OrderPreview__FieldView}
						/>

						<FieldView
							label='Card Tagline'
							className={styles.OrderPreview__FieldView}
						>
							<span className={styles.OrderPreview__Tagline}>
								{selectedTagline ? `“${selectedTagline}”` : null}
							</span>
						</FieldView>

						<FieldView
							label='Message'
							labelDescription='(Optional)'
							className={styles.OrderPreview__FieldView}
						>
							<TextArea
								name="emailMessageTextArea"
								description={`* Your message can be up ${textAreaMaxLength} characters.`}
								className={styles.OrderPreview__TextArea}
								maxLength={textAreaMaxLength}
								onChange={onChangeTextArea}
								cols={30}
								rows={4}
							/>
						</FieldView>

						<FieldView
							label='From'
							value={orderInformation?.from}
							valueDescription={orderInformation?.senderEmail}
							className={styles.OrderPreview__FieldView}
						/>
					</div>

					<Button
						className={styles.OrderPreview__SubmitButton}
						disabled={disableSubmitButton}
						onClick={onSubmitButtonClick}
					>
						Place Order
					</Button>

					{submitErrorsList?.map(error => (
						<span
							className={styles.OrderPreview__Error}
							key={error}
						>
							{error}
						</span>
					))}
				</div>
			</div>
		</section>
	);
};

interface IOrderInformation {
	to?: string;
	from?: string;
	recipientEmail?: string;
	senderEmail?: string;
}

type TPaymentCardProps = ComponentProps<typeof PaymentCard>;
