import styles from './HeaderView.module.scss';

import cn from 'classnames';

export const HeaderView = () => {
	return (
		<section className={cn('Content__Bg', styles.HeaderView)}>
			<div>
				<h1 className={styles.HeaderView__Title}>‘TIS THE SEASON TO GIFT SHRED</h1>
				<h3 className={styles.HeaderView__PreTitle}>Give the gift of health & fitness to all of your people</h3>

				<div className={styles.HeaderView__Hr}>
					<div className={styles.HeaderView__Arrow} />
					<div className={styles.HeaderView__Arrow} />
					<div className={styles.HeaderView__Arrow} />
					<div className={styles.HeaderView__Arrow} />
				</div>

				<span className={styles.HeaderView__Text}>
					SHRED is a digital fitness studio for everyone. We’ve <br />
					already helped thousands of people from around the world <br />
					to reach their fitness goals. This holiday season, we are <br />
					making it easy for you to give a meaningful gift that your <br />
					friends & family will actually benefit from to reach their <br />
					goals in 2024.<br />
				</span>

				<span className={styles.HeaderView__FooterText}>
					Shred Premium<br />
					A Gift that keeps on giving all year long!
				</span>
			</div>
			<button 
				className={styles.HeaderView__CTAButton} 
				onClick={() => document.getElementById('paymentCardSection')?.scrollIntoView({ behavior: 'smooth' })}
			>
				CREATE CARD
			</button>
		</section>
	);
};
