import styles from './SelectPaymentCard.module.scss';

import { Title, PaymentCard } from '../../shared';

import cn from 'classnames';

import type { ComponentProps } from 'react';

interface IProps {
	paymentCardsList: ICard[];
	onSelectPaymentCard: (selectedCard: ICard) => void;
}

export const SelectPaymentCard = ({ paymentCardsList, onSelectPaymentCard }: IProps) => {
	const onSelectCard = (selectedCard: ICard) => () => {
		if (!selectedCard.selected) {
			onSelectPaymentCard(selectedCard);
		}
	};

	return (
		<section id="paymentCardSection" className={cn('Content__Bg Content__Bg_gray', styles.SelectPaymentCard)}>
			<Title>
				<span>1.</span>
				{' '} Select Your CARD
			</Title>

			<div className={styles.SelectPaymentCard__CardsContainer}>
				{paymentCardsList.map(card => (
					<PaymentCard
						className={styles.SelectPaymentCard__PaymentCard}
						card={card}
						key={card.id}
						onClick={onSelectCard(card)}
					/>
				))}
			</div>
		</section>
	);
};

interface ICard extends TPaymentCardProps {
	id: number;
}

type TPaymentCardProps = ComponentProps<typeof PaymentCard>['card'];
