import styles from './Header.module.scss';

import { Logo } from '../../shared';

import cn from 'classnames';

interface IProps {
	onLogoClick: () => void;
}

export const Header = ({ onLogoClick }: IProps) => {
	return (
		<header className={cn('Content__Bg Content__Bg_black', styles.Header)}>
			<div className={cn('content', styles.Header__Content)}>
				<Logo
					onClick={onLogoClick}
					className={styles.Header__Logo}
				/>
			</div>
		</header>
	);
};
