
import './scss/index.scss';
import { AppRoutes } from './AppRoutes';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { StrictMode } from 'react';

const App = () => {
	return (
		<StrictMode>
			<Provider store={store}>
				<AppRoutes />
			</Provider>
		</StrictMode>
	);
};

render(<App />, document.getElementById('root'));

serviceWorker.register();
