import {
	SelectPaymentCardContainer,
	PremiumInfoContainer,
	SelectTaglinesContainer,
	OrderInformationContainer,
	OrderPreviewContainer,
	PromocodeWarningContainer
} from '../../containers';
import { HeaderView } from '../../components';

export const HomePage = () => {
	return (
		<>
			<HeaderView />
			<PremiumInfoContainer />
			<SelectPaymentCardContainer />
			<SelectTaglinesContainer />
			<OrderInformationContainer />
			<OrderPreviewContainer />
			<PromocodeWarningContainer />
		</>
	);
};
