import styles from './Button.module.scss';

import cn from 'classnames';

import type { ButtonHTMLAttributes } from 'react';

export const Button = ({ className, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) => {
	return (
		<button
			className={cn(
				styles.Button,
				className)}
			{...rest}
		/>
	);
};
