import styles from './OrderInformation.module.scss';
import { schema, getValidationError, recipientEmailIsValid } from './validationSchema';

import { Title, Field } from '../../shared';

import { useState, useEffect, useCallback } from 'react';

import type { ChangeEvent } from 'react';
import type { ValidationResult } from 'joi';
import type { ISchema } from './validationSchema';

interface IProps {
	recipientEmailCheckProcessing: boolean;
	recipientEmailCheckErrorMessage: string | null;
	onUpdateField: (data: IOnUpdateField) => void;
	onTypeRecipientEmail: (email: string) => void;
	onChangeRecipientEmail: () => void;
}

export const OrderInformation = ({
	onUpdateField,
	onTypeRecipientEmail,
	recipientEmailCheckProcessing,
	recipientEmailCheckErrorMessage,
	onChangeRecipientEmail
}: IProps) => {
	const [validation, setValidation] = useState<ValidationResult | null>(null);
	const [orderInformation, setOrderInformation] = useState<TOrderInformation | null>(null);
	const [timeoutValue, setTimeoutValue] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (timeoutValue) {
				clearTimeout(timeoutValue);
			}
		};
	}, [timeoutValue]);

	const afterChangeRecipientEmail = useCallback((recipientEmailValue: string) => {
		const isValidEmail = recipientEmailIsValid({ recipientEmailValue });

		if (timeoutValue) {
			clearTimeout(timeoutValue);
		}

		if (!isValidEmail) {
			return;
		}

		setTimeoutValue(setTimeout(() => {
			onTypeRecipientEmail(recipientEmailValue);
		}, 2000));
	}, [onTypeRecipientEmail, timeoutValue]);

	

	const onChangeField = (type: keyof TOrderInformation) => (e: ChangeEvent<HTMLInputElement>) => {
		const data = { [type]: e.target.value };
		const newOrderInformationData = { ...orderInformation, ...data };
		const validationResult = schema.validate(newOrderInformationData);

		if (type === 'recipientEmail') {
			onChangeRecipientEmail();
			afterChangeRecipientEmail(e.target.value);
		}

		setOrderInformation(newOrderInformationData);
		setValidation(validationResult);
		onUpdateField({
			orderInformation: { ...newOrderInformationData },
			orderInformationIsValid: !Boolean(validationResult.error)
		});
	};

	return (
		<section className={styles.OrderInformation}>
			<Title>
				<span>3.</span> Order Information
			</Title>
			<div className={styles.OrderInformation__FieldBlocksContainer}>
				<div className={styles.OrderInformation__FieldBlock}>
					<Field
						label='Your Name'
						name='from'
						placeholder='Sender’s Name'
						onChange={onChangeField('from')}
						message={getValidationError('from', validation)}
					/>
					<Field
						label='Your Email Addres'
						name='senderEmail'
						placeholder='Enter email'
						type='email'
						onChange={onChangeField('senderEmail')}
						message={getValidationError('senderEmail', validation)}
					/>
				</div>
				<div className={styles.OrderInformation__FieldBlock}>
					<Field
						label='Their Name'
						name='to'
						placeholder='Recipient’s Name'
						onChange={onChangeField('to')}
						message={getValidationError('to', validation)}
					/>
					<Field
						label='Their Email Address'
						name='recipientEmail'
						placeholder='Enter email'
						type='email'
						onChange={onChangeField('recipientEmail')}
						message={getValidationError('recipientEmail', validation) || recipientEmailCheckErrorMessage || ''}
						disabled={recipientEmailCheckProcessing}
					/>
					<Field
						label='Confirm Their Email Address'
						name='repeatRecipientEmail'
						placeholder='Enter email'
						type='email'
						onChange={onChangeField('repeatRecipientEmail')}
						message={getValidationError('repeatRecipientEmail', validation)}
						onPaste={e => e.preventDefault()}
					/>
				</div>
			</div>
		</section>
	  );
};

type TOrderInformation = Partial<ISchema>;

interface IOnUpdateField {
	orderInformation: TOrderInformation;
	orderInformationIsValid: boolean;
}
