import styles from './PremiumInfo.module.scss';

import { Title } from '../../shared';

interface IProps {
	planPrice?: number | string | null;
}

export const PremiumInfo = ({ planPrice }: IProps) => {
	return (
		<section className={styles.PremiumInfo}>
			{planPrice ? (
				<span className={styles.PremiumInfo__Price}>
					${planPrice}
				</span>
			) : null}

			<Title>
				<span>
					1-YEAR of SHRED PREMIUM
				</span>
				{' '} - 40% OFF
			</Title>

			<div className={styles.PremiumInfo__CardsContainer}>
				<div className={styles.PremiumInfo__Card}>
					<span>
						Give unlimited Premium Access to world-class workouts created
						by top fitness trainers who coach you every step of the way.
					</span>
				</div>

				<div className={styles.PremiumInfo__Card}>
					<span>
						Follow along with dozens of
						on-demand video programs
						and coaching plans
						to help you reach your goals.
					</span>
				</div>

				<div className={styles.PremiumInfo__Card}>
					<span>
						Select from Home or Gym based programs that span a wide range of
						fitness genres including, strength training, HIIT, Cardio,
						Yoga, Pilates, Mindset + Mindfulness, Breathwork, Active Recovery / Stretching.
					</span>
				</div>
			</div>
		</section>
	);
};
