import { Modal } from '../../shared';
import { useAppSelector, useAppDispatch} from '../../redux/hooks';
import { getPromocodeStatus, getPageProperties } from '../../redux/reducers';

import { useEffect } from 'react';

export const PromocodeWarningContainer = () => {
	const giftErrorMessage = useAppSelector(state => state.gift.giftErrorMessage);
	const pageProperties = useAppSelector(state => state.gift.pageProperties);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getPageProperties());
	}, [dispatch]);

	useEffect(() => {
		if (!pageProperties || !pageProperties?.promotionCode) {
			return;
		}

		dispatch(getPromocodeStatus({
			promoCode: pageProperties?.promotionCode,
		}));
	}, [dispatch, pageProperties]);

	useEffect(() => {
		if (giftErrorMessage) {
			document.body.style.overflow = 'hidden';
			window.scrollTo(0, 0);
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [giftErrorMessage]);

	return (
		giftErrorMessage && (
			<Modal
				title={giftErrorMessage?.message}
				preTitle={giftErrorMessage?.description}
				show={!!giftErrorMessage}
			/>
		)
	);
};
