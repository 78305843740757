import { SelectTaglines } from '../../components';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectTagline } from '../../redux/reducers';

import type { ITagline } from '../../redux/reducers';

export const SelectTaglinesContainer = () => {
	const leftTaglinesList = useAppSelector(state => state.tagline.leftTaglinesList);
	const rightTaglinesList = useAppSelector(state => state.tagline.rightTaglinesList);
	const dispatch = useAppDispatch();

	const onSelectTagline = (tagline: ITagline) => {
		dispatch(selectTagline(tagline));
	};

	return (
		<SelectTaglines
			leftTaglinesList={leftTaglinesList}
			rightTaglinesList={rightTaglinesList}
			onSelectTagline={onSelectTagline}
		/>
	);
};
