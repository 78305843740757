import { leftTaglinesList, rightTaglinesList } from './data/taglines.data';

import { selectedTaglineStorage } from '../../localStorage';

import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ITagline } from './data/taglines.data';

const initialState: ITaglineState = {
	leftTaglinesList,
	rightTaglinesList,
	selectedTagline: selectedTaglineStorage.getItemAsJson(),
};

const taglineSlice = createSlice({
	name: 'tagline',
	initialState,
	reducers: {
		selectTagline: (state, { payload: tagline }: PayloadAction<ITagline>) => {
			state.selectedTagline = tagline;

			selectedTaglineStorage.setItemAsJson(tagline);
		},
	},
});

export const taglineReducer = taglineSlice.reducer;

export const { selectTagline } = taglineSlice.actions;

export type { ITagline } from './data/taglines.data';

interface ITaglineState {
	leftTaglinesList: ITagline[];
	rightTaglinesList: ITagline[];
	selectedTagline: ITagline | null;
}
