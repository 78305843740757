import styles from './NotFound.module.scss';

import { Title } from '../../shared';

interface IProps {
	onGoHomeClick: () => void;
}

export const NotFound = ({ onGoHomeClick }: IProps) => {
	return (
		<section>
			<Title>
				Page Not Found
			</Title>

			<Title>
				<span
					className={styles.NotFound__Link}
					onClick={onGoHomeClick}
				>
					go Home
				</span>
			</Title>
		</section>
	);
};
