import { LocalStorage } from './localStorage';

class SelectedTaglineStorage extends LocalStorage {
	public constructor() {
		super({
			storageKey: 'selected_tagline'
		});
	}
}

export const selectedTaglineStorage = new SelectedTaglineStorage();
