import { Controller } from '../../config';

import type { IGetPagePropertiesResponse } from './response.types';

class PagePropertiesController extends Controller {
	public constructor() {
		super({
			requestUrl: `${process.env.REACT_APP_BASE_DOMAIN}/promo`
		});
	}

	public async getPageProperties() {
		return this.get<IGetPagePropertiesResponse>('/page-properties');
	}
}

export const pagePropertiesAPI = new PagePropertiesController();
