import { NotFound } from '../../components';

import { useNavigate } from 'react-router-dom';

interface IProps {
	homePagePath: string;
}

export const NotFoundPage = ({ homePagePath }: IProps) => {
	const navigate = useNavigate();

	const onGoHomeClick = () => {
		navigate(homePagePath);
	};

	return (
		<NotFound onGoHomeClick={onGoHomeClick} />
	);
};
