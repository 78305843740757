import { paymentCardsList } from './data/cards.data';

import { selectedPaymentCardStorage } from '../../localStorage';

import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ICard } from './data/cards.data';

const initialState: IPaymentCardsState = {
	paymentCardsList,
	selectedPaymentCard: selectedPaymentCardStorage.getItemAsJson()
};

const paymentCardsSlice = createSlice({
	name: 'paymentCards',
	initialState,
	reducers: {
		selectPaymentCard: (state, { payload: selectedCard }: PayloadAction<ICard>) => {
			const newPaymentCardsList = state.paymentCardsList.map(card => ({
				...card,
				selected: Boolean(card.id === selectedCard.id)
			}));

			state.paymentCardsList = newPaymentCardsList;
			state.selectedPaymentCard = selectedCard;

			selectedPaymentCardStorage.setItemAsJson(selectedCard);
		},
	},
});

export const paymentCardsReducer = paymentCardsSlice.reducer;

export const { selectPaymentCard } = paymentCardsSlice.actions;

interface IPaymentCardsState {
	paymentCardsList: ICard[];
	selectedPaymentCard: ICard | null;
}
