import { Header } from '../../components';

import { useNavigate } from 'react-router-dom';

interface IProps {
	homePagePath: string;
}

export const HeaderPage = ({ homePagePath }: IProps) => {
	const navigate = useNavigate();

	const onLogoClick = () => {
		navigate(homePagePath);
	};

	return (
		<Header onLogoClick={onLogoClick} />
	);
};
