import styles from './Envelope.module.scss';

import cn from 'classnames';

import type { HTMLAttributes } from 'react';

export const Envelope = ({ className, children }: HTMLAttributes<HTMLDivElement>,) => {
	return (
		<div className={cn(styles.Envelope, className)}>
			{children}
		</div>
	);
};
