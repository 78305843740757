export const leftTaglinesList: ITagline[] = [
	{
		label: '‘Tis the Season to Be Shredded',
		id: 1
	},
	{
		label: 'Booty Gains With a Bow on Top',
		id: 2
	},
	{
		label: '2023 - THIS IS GONNA BE OUR YEAR',
		id: 3
	}
];

export const rightTaglinesList: ITagline[] = [
	{
		label: 'Get Shredded & Be Kind to People',
		id: 4
	},
	{
		label: 'I’m Not Calling You Lazy',
		id: 5
	},
	{
		label: '2023 - Don’t Let Anyone Waste Your Time',
		id: 6
	}
];

export interface ITagline {
	label: string;
	id: number;
	selected?: boolean;
}
