import firstCardBg from './images/1.png';
import secondCardBg from './images/2.svg';
import thirdCardBg from './images/3.svg';
import fourthCardBg from './images/4.svg';
import fifthCardBg from './images/5.svg';
import sixthCardBg from './images/6.svg';

export const paymentCardsList: ICard[] = [
	{
		bgImage: firstCardBg,
		id: 1,
	},
	{
		bgImage: secondCardBg,
		id: 2
	},
	{
		bgImage: thirdCardBg,
		id: 3,
	},
	{
		bgImage: fourthCardBg,
		id: 4,
	},
	{
		bgImage: fifthCardBg,
		id: 5,
	},
	{
		bgImage: sixthCardBg,
		id: 6,
	},
];

export interface ICard {
	bgImage: string;
	id: number;
	selected?: boolean;
}
