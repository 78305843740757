import styles from './PaymentCard.module.scss';

import cn from 'classnames';

import type { HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {
	card: ICard;
}

export const PaymentCard = ({ card, className, ...rest }: IProps) => {
	return (
		<div
			className={cn(
				styles.PaymentCard,
				card.selected && styles.PaymentCard_selected,
				className
			)}
			style={{
				backgroundImage: `url(${card.bgImage})`,
			}}
			{...rest}
		/>
	);
};

interface ICard {
	bgImage: string;
	selected?: boolean;
}
