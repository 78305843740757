import { LocalStorage } from './localStorage';

class SelectedPaymentCardStorage extends LocalStorage {
	public constructor() {
		super({
			storageKey: 'selected_payment_card'
		});
	}
}

export const selectedPaymentCardStorage = new SelectedPaymentCardStorage();
