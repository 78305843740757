import { StripeAPI, pagePropertiesAPI } from '../../../api';

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ICreateGiftPaymentRequest, IGetPlanNameRequest, IGetPlanPriceRequest, IGetPromocodeStatusRequest } from '../../../api';

const stripePrefix = 'stripe';
const pagePropertiesPrefix = 'promo';

export const createGiftPayment = createAsyncThunk(
	`${stripePrefix}/createGiftPayment`,
	async (data: ICreateGiftPaymentRequest) => {
		return StripeAPI.createGiftPayment(data)
			.then(response => response.data)
			.catch(e => {
				throw new Error(e.response.data.message);
			});
	}
);

export const getPlanName = createAsyncThunk(
	`${stripePrefix}/getPlanName`,
	async (data: IGetPlanNameRequest) => {
		return await StripeAPI.getPlanName(data)
			.then(response => response.data.response)
			.catch(e => {
				throw new Error(e.response.data.message);
			});
	}
);

export const getPageProperties = createAsyncThunk(
	`${pagePropertiesPrefix}/getPageProperties`,
	async () => {
		return await pagePropertiesAPI.getPageProperties()
			.then(response => response.data)
			.catch(e => {
				throw new Error(e.response.data.message);
			});
	}
);

export const getPlanPrice = createAsyncThunk(
	`${stripePrefix}/getPlanPrice`,
	async (data: IGetPlanPriceRequest) => {
		return await StripeAPI.getPlanPrice(data)
			.then(response => response.data.response)
			.catch(e => {
				throw new Error(e.response.data.message);
			});
	}
);

export const getPromocodeStatus = createAsyncThunk(
	`${stripePrefix}/getPromocodeStatus`,
	async (data: IGetPromocodeStatusRequest) => {
		const response = await StripeAPI.getPromocodeStatus(data);

		return response.data;
	}
);
