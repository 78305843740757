import { PremiumInfo } from '../../components';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { getPlanName, getPlanPrice, getPageProperties } from '../../redux/reducers';

import { useEffect } from 'react';

export const PremiumInfoContainer = () => {
	const planPrice = useAppSelector(state => state.gift.planPrice);
	const pageProperties = useAppSelector(state => state.gift.pageProperties);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getPageProperties());
	}, [dispatch]);

	useEffect(() => {
		if (!pageProperties || !pageProperties?.promotionCode || !pageProperties?.planId) {
			return;
		}

		dispatch(getPlanName({
			planId: pageProperties?.planId || ''
		}));

		dispatch(getPlanPrice({
			planId: pageProperties?.planId || '',
			promoCode: pageProperties?.promotionCode || ''
		}));
	}, [dispatch, pageProperties]);

	return (
		<PremiumInfo planPrice={planPrice && (parseInt(planPrice, 10) / 100).toFixed(2)} />
	);
};
