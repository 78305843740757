import { SelectPaymentCard } from '../../components';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectPaymentCard } from '../../redux/reducers';

import type { ComponentProps } from 'react';

export const SelectPaymentCardContainer = () => {
	const paymentCardsList = useAppSelector(state => state.paymentCards.paymentCardsList);
	const dispatch = useAppDispatch();

	const onSelectPaymentCard: TSelectPaymentCardProps['onSelectPaymentCard'] = (selectedCard) => {
		dispatch(selectPaymentCard(selectedCard));
	};

	return (
		<SelectPaymentCard
			onSelectPaymentCard={onSelectPaymentCard}
			paymentCardsList={paymentCardsList}
		/>
	);
};

type TSelectPaymentCardProps = ComponentProps<typeof SelectPaymentCard>;
