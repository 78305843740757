import { giftPaymentCheckEmail } from './api.actions';

import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IOrderInformationState = {
	orderInformation: null,
	orderInformationIsValid: false,
	isProcessing: false,
	isSuccessRecipientEmail: false,
	recipientEmailCheckErrorMessage: null
};

const orderInformationSlice = createSlice({
	name: 'orderInformation',
	initialState,
	reducers: {
		updateOrderInformation: (state, { payload: orderInformation }: PayloadAction<IOrderInformation>) => {
			state.orderInformation = {
				...state.orderInformation,
				...orderInformation
			};
		},
		updateOrderInformationIsValid: (state, { payload: orderInformationIsValid }: PayloadAction<IOrderInformationState['orderInformationIsValid']>) => {
			state.orderInformationIsValid = orderInformationIsValid;
		},
		clearRecipientState: (state) => {
			state.recipientEmailCheckErrorMessage = null;
			state.isSuccessRecipientEmail = false;
		}
	},
	extraReducers: (builder) => {
		builder
			// giftPaymentCheckEmail
			.addCase(giftPaymentCheckEmail.pending, (state) => {
				state.isProcessing = true;
				state.recipientEmailCheckErrorMessage = null;
				state.isSuccessRecipientEmail = false;
			})
			.addCase(giftPaymentCheckEmail.rejected, (state, action) => {
				state.isProcessing = false;

				if (action.error.message === '403') {
					state.recipientEmailCheckErrorMessage = `We're sorry, but ${state.orderInformation?.recipientEmail} is already an active Shred user. Perhaps send your gift to another family or friend?`;
				}

				if (action.error.message === '500') {
					state.recipientEmailCheckErrorMessage = 'An Internal Error Occurred. Please try again or contact support@shred.app';
				}

				state.isSuccessRecipientEmail = false;
			})
			.addCase(giftPaymentCheckEmail.fulfilled, (state) => {
				state.isProcessing = false;
				state.recipientEmailCheckErrorMessage = null;
				state.isSuccessRecipientEmail = true;
			});
	}
});

export const orderInformationReducer = orderInformationSlice.reducer;

export const {
	updateOrderInformation,
	updateOrderInformationIsValid,
	clearRecipientState
} = orderInformationSlice.actions;

interface IOrderInformationState {
	orderInformation: IOrderInformation | null;
	orderInformationIsValid: boolean;
	isProcessing: boolean;
	isSuccessRecipientEmail: boolean;
	recipientEmailCheckErrorMessage: string | null;
}

interface IOrderInformation {
	to?: string;
	from?: string;
	recipientEmail?: string;
	senderEmail?: string;
}
