import { Controller } from '../../config';

import type {
	IGiftPaymentCheckEmailRequest,
	ICreateGiftPaymentRequest,
	IGetPlanNameRequest,
	IGetPlanPriceRequest,
	IGetPromocodeStatusRequest
} from './request.types';
import type {
	IGiftPaymentCheckEmailResponse,
	IGetPromocodeStatusResponse,
	ICreateGiftPaymentResponse,
	IGetPlanNameResponse,
	IGetPlanPriceResponse
} from './response.types';

class StripeController extends Controller {
	public constructor() {
		super({
			requestUrl: `${process.env.REACT_APP_BASE_DOMAIN}/stripe`
		});
	}

	public async giftPaymentCheckEmail(data: IGiftPaymentCheckEmailRequest) {
		return this.post<IGiftPaymentCheckEmailResponse>('/gift-payment/check-email', data);
	}

	public async createGiftPayment(data: ICreateGiftPaymentRequest) {
		return this.post<ICreateGiftPaymentResponse>('/gift-payment', data);
	}

	public async getPlanName({ planId }: IGetPlanNameRequest) {
		return this.get<IGetPlanNameResponse>(`/plan/${planId}`);
	}

	public async getPlanPrice(data: IGetPlanPriceRequest) {
		return this.post<IGetPlanPriceResponse>('/price', data);
	}

	public async getPromocodeStatus({ promoCode }: IGetPromocodeStatusRequest) {
		return this.get<IGetPromocodeStatusResponse>('/check-promocode/', {
			params: {
				promocode: promoCode,
			}
		});
	}
}

export const StripeAPI = new StripeController();
