import styles from './Field.module.scss';

import cn from 'classnames';

import type { InputHTMLAttributes } from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	name: string;
	message?: string;
}

export const Field = ({
	label,
	className,
	name,
	id,
	message,
	...rest
}: IProps) => {
	const fieldId = id || name;

	return (
		<div className={cn(styles.Field, className)}>
			<label
				className={styles.Field__Label}
				htmlFor={fieldId}
			>
				{label}
			</label>

			<input
				type="text"
				id={fieldId}
				name={name}
				className={styles.Field__Input}
				{...rest}
			/>

			{message && (
				<span className={cn(
					styles.Field__Message,
					styles.Field__Message_error,
				)}
				>
					{message}
				</span>
			)}
		</div>
	);
};
