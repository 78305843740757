import { OrderPreview } from '../../components';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { createGiftPayment } from '../../redux/reducers';

export const OrderPreviewContainer = () => {
	const orderInformation = useAppSelector(state => state.orderInformation.orderInformation);
	const isOrderInfoValid = useAppSelector(state => state.orderInformation.orderInformationIsValid);
	const isSuccessRecipientEmail = useAppSelector(state => state.orderInformation.isSuccessRecipientEmail);
	const selectedTagline = useAppSelector(state => state.tagline.selectedTagline?.label);
	const selectedPaymentCard = useAppSelector(state => state.paymentCards.selectedPaymentCard);
	const isSubmitProcessing = useAppSelector(state => state.gift.isProcessing);
	const submitErrorsList = useAppSelector(state => state.gift.submitErrors);
	const pageProperties = useAppSelector(state => state.gift.pageProperties);
	const dispatch = useAppDispatch();

	const shouldDisableSubmitButton = !isOrderInfoValid
		|| !isSuccessRecipientEmail
		|| !selectedTagline
		|| !selectedPaymentCard
		|| isSubmitProcessing;

	const onSubmit = (message: string) => {
		dispatch(createGiftPayment({
			planId: pageProperties?.planId || '',
			recipient: {
				name: orderInformation?.to || '',
				email: orderInformation?.recipientEmail || ''
			},
			sender: {
				name: orderInformation?.from || '',
				email: orderInformation?.senderEmail || ''
			},
			card: {
				designName: selectedPaymentCard?.bgImage || '',
				tagline: selectedTagline || ''
			},
			message,
			promotionCode: pageProperties?.promotionCode || ''
		}));
	};

	return (
		<OrderPreview
			orderInformation={orderInformation}
			selectedTagline={selectedTagline}
			disableSubmitButton={shouldDisableSubmitButton}
			selectedPaymentCard={selectedPaymentCard}
			submitErrorsList={submitErrorsList}
			onSubmit={onSubmit}
		/>
	);
};
