import styles from './FieldView.module.scss';

import cn from 'classnames';

import type { HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {
	label: string;
	labelDescription?: string;
	value?: string;
	valueDescription?: string;
	withoutBorder?: boolean;
}

export const FieldView = ({
	className,
	label,
	value,
	labelDescription,
	valueDescription,
	withoutBorder,
	children,
	...rest
}: IProps) => {
	return (
		<div
			className={cn(
				styles.FieldView,
				withoutBorder && styles.FieldView_withoutBorder,
				className
			)}
			{...rest}
		>
			<div className={styles.FieldView__Container}>
				<span className={styles.FieldView__Label}>
					{label}

					{labelDescription ? (
						<span className={styles.FieldView__LabelDescription}>
							{labelDescription}
						</span>
					) : null}
				</span>

				<span className={styles.FieldView__Value}>
					{value}

					{valueDescription ? (
						<span className={styles.FieldView__ValueDescription}>
							{valueDescription}
						</span>
					) : null}
				</span>
			</div>

			{children}
		</div>
	);
};
