import Joi from 'joi';

import type { ValidationResult } from 'joi';

const recipientEmailSchema = Joi.string()
	.email({
		minDomainSegments: 2,
		tlds: { allow: false }
	})
	.label('Recipient’s Email Address')
	.required();

export const schema = Joi.object<ISchema>({
	to: Joi.string()
		.label('Recipient’s Name')
		.required(),

	recipientEmail: recipientEmailSchema,

	repeatRecipientEmail:
		Joi.string()
			.email({
				minDomainSegments: 2,
				tlds: { allow: false }
			})
			.label('Confirm Recipient’s Email Address')
			.required()
			.valid(Joi.ref('recipientEmail'))
			.options({ messages: { 'any.only': '{{#label}} does not match' } }),

	from: Joi.string()
		.label('Sender’s Name')
		.required(),

	senderEmail: Joi.string()
		.email({
			minDomainSegments: 2,
			tlds: { allow: false }
		})
		.label('Sender’s Email Address')
		.required()
		.invalid(Joi.ref('recipientEmail'))
		.options({ messages: { 'any.invalid': '{{#label}} value must be not equal recipient email' } }),
});

export const getValidationError = (type: keyof ISchema, validationResult: ValidationResult | null) => {
	if (validationResult && validationResult.error) {
		const details = validationResult.error.details[0];

		if (details && details.context?.key === type) {
			return validationResult.error.message;
		}
	}

	return '';
};

export const recipientEmailIsValid = ({ recipientEmailValue }: IRecipientEmailIsValidProps) => {
	if (!recipientEmailValue) {
		return;
	}

	const recipientEmailValidation = recipientEmailSchema.validate(recipientEmailValue);

	return !Boolean(recipientEmailValidation.error);
};

interface IRecipientEmailIsValidProps {
	recipientEmailValue?: string;
}

export interface ISchema {
	to: string;
	from: string;
	recipientEmail: string;
	repeatRecipientEmail: string;
	senderEmail: string;
}
