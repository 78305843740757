import { StripeAPI } from '../../../api';

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IGiftPaymentCheckEmailRequest } from '../../../api';

const prefix = 'stripe';

export const giftPaymentCheckEmail = createAsyncThunk(
	`${prefix}/giftPaymentCheckEmail`,
	async (data: IGiftPaymentCheckEmailRequest) => {
		return StripeAPI.giftPaymentCheckEmail(data)
			.then(response => response.status)
			.catch(e => {
				throw new Error(e.response.status);
			});
	}
);
