import styles from './SuccessPayment.module.scss';

import { Envelope, PaymentCard, Title } from '../../shared';

import type { ComponentProps } from 'react';

interface IProps {
	selectedPaymentCard: TPaymentCardProps['card'] | null;
	selectedTagline: string | undefined;
}

export const SuccessPayment = ({ selectedPaymentCard, selectedTagline }: IProps) => {
	return (
		<section className={styles.SuccessPayment}>
			<Envelope className={styles.SuccessPayment__Envelope}>
				{selectedPaymentCard && (
					<PaymentCard card={selectedPaymentCard} />
				)}

				<Title className={styles.SuccessPayment__Tagline}>
					<span>
						{selectedTagline}
					</span>
				</Title>
			</Envelope>

			<Title>Congratulations</Title>

			<Title>
				<span>
					payment was successful
				</span>
			</Title>
		</section>
	);
};

type TPaymentCardProps = ComponentProps<typeof PaymentCard>
