import styles from './Title.module.scss';

import cn from 'classnames';

import type { HTMLAttributes } from 'react';

export const Title = ({ className, ...rest }: HTMLAttributes<HTMLSpanElement>) => {
	return (
		<span
			className={cn(styles.Title, className)}
			{...rest}
		/>
	);
};
