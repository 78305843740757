import styles from './SelectTaglines.module.scss';

import { Title, CheckBox } from '../../shared';

import cn from 'classnames';

interface IProps {
	leftTaglinesList: ITagline[];
	rightTaglinesList: ITagline[];
	onSelectTagline: (tagline: ITagline) => void;
}

export const SelectTaglines = ({ leftTaglinesList, rightTaglinesList, onSelectTagline }: IProps) => {
	const renderCheckBox = (tagline: ITagline, i: number) => (
		<CheckBox
			key={i}
			id={`${tagline.id}`}
			label={tagline.label}
			onChange={() => onSelectTagline(tagline)}
			name='paymentTagline'
		/>
	);

	return (
		<section className={cn('Content__Bg Content__Bg_gray', styles.SelectTaglines)}>
			<Title>
				<span>2.</span>
				{' '} Select CARD TAGLINES
			</Title>

			<div className={styles.SelectTaglines__BlocksContainer}>
				<div className={cn(styles.SelectTaglines__Block, styles.SelectTaglines__Block_left)}>
					{leftTaglinesList.map(renderCheckBox)}
				</div>

				<div className={styles.SelectTaglines__Block}>
					{rightTaglinesList.map(renderCheckBox)}
				</div>
			</div>
		</section>
	);
};

interface ITagline {
	label: string;
	id: number;
	selected?: boolean;
}
