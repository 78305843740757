import {
	createGiftPayment,
	getPromocodeStatus,
	getPlanName,
	getPageProperties,
	getPlanPrice
} from './api.actions';

import { createSlice } from '@reduxjs/toolkit';

const initialState: IGiftState = {
	planPrice: null,
	planName: null,
	pageProperties: null,
	giftErrorMessage: null,
	isProcessing: false,
	submitErrors: null
};

const giftSlice = createSlice({
	name: 'gift',
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			// createGiftPayment
			.addCase(createGiftPayment.pending, (state) => {
				state.isProcessing = true;
				state.submitErrors = null;
			})
			.addCase(createGiftPayment.rejected, (state, action) => {
				state.isProcessing = false;

				if (action.error.message) {
					state.submitErrors = [action.error.message];
				}
			})
			.addCase(createGiftPayment.fulfilled, (state, action) => {
				state.isProcessing = false;

				if (action.payload.url) {
					window.open(action.payload.url, '_self');
				}

				if (action.payload.errors) {
					const { errors } = action.payload;

					state.submitErrors = Object.keys(errors).map(key => errors[key][0]);
				}
			})

			// getPageProperties
			.addCase(getPageProperties.fulfilled, (state, action) => {
				if (action.payload.code !== 200) {
					state.giftErrorMessage = {
						message: 'Failed to retrieve page properties',
						description: action.payload.message,
					};

					return;
				}

				state.pageProperties = {
					planId: action.payload.data?.planId,
					promotionCode: action.payload.data?.promotionCode
				};
			})
			.addCase(getPageProperties.rejected, (state, action) => {
				if (action.error.message) {
					state.giftErrorMessage = {
						message: 'Page properties not available',
						description: action.error.message,
					};
				}
			})
			// getPromocodeStatus
			.addCase(getPromocodeStatus.fulfilled, (state, action) => {
				if (!action.payload.status) {
					state.giftErrorMessage = {
						message: 'Promotional page is not available',
						description: 'This promotion code isn\'t available at the moment',
					};
				}
			})
			// getPlanName
			.addCase(getPlanName.fulfilled, (state, action) => {
				state.planName = action.payload.name;
			})
			.addCase(getPlanName.rejected, (state, action) => {
				if (action.error.message) {
					state.giftErrorMessage = {
						message: 'Promotional page is not available',
						description: action.error.message,
					};
				}
			})
			// getPlanPrice
			.addCase(getPlanPrice.fulfilled, (state, action) => {
				state.planPrice = action.payload.amount;
			})
			.addCase(getPlanPrice.rejected, (state, action) => {
				if (action.error.message) {
					state.giftErrorMessage = {
						message: 'Promotional page is not available',
						description: action.error.message,
					};
				}
			})
		;
	}
});

export const giftReducer = giftSlice.reducer;

interface IGiftState {
	planPrice: string | null;
	planName: string | null;
	pageProperties: IPageProperties | null;
	giftErrorMessage: IGiftErrorMessage | null;
	isProcessing: boolean;
	submitErrors: string[] | null;
}

interface IPageProperties {
	planId: string;
	promotionCode: string;
}

interface IGiftErrorMessage {
	message: string;
	description: string;
}
