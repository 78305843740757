import styles from './CheckBox.module.scss';

interface IProps {
	label: string;
	id: string;
	name: string;
	onChange: () => void;
}

export const CheckBox = ({ label, id, name, onChange }: IProps) => {
	return (
		<div>
			<input
				className={styles.CheckBox__Input}
				type="radio"
				id={id}
				name={name}
				onChange={onChange}
			/>

			<label htmlFor={id}>
				{label}
			</label>
		</div>
	);
};
