import { Footer } from './components';
import {
	HeaderPage,
	HomePage,
	NotFoundPage,
	SuccessPaymentPage
} from './pages';

import {
	Route,
	Routes,
	BrowserRouter
} from 'react-router-dom';

const routesPaths = {
	homePage: '/',
	successPaymentPage: '/success',
	notFoundPage: '*'
};

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<section className='Content'>
				<HeaderPage homePagePath={routesPaths.homePage} />

				<Routes>
					<Route
						path='/'
						element={<HomePage />}
					/>

					<Route
						path='/success'
						element={<SuccessPaymentPage />}
					/>

					<Route
						path='*'
						element={<NotFoundPage homePagePath={routesPaths.homePage} />}
					/>
				</Routes>

				<Footer />
			</section>
		</BrowserRouter>
	);
};
