import { OrderInformation } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
	updateOrderInformation,
	updateOrderInformationIsValid,
	giftPaymentCheckEmail,
	clearRecipientState
} from '../../redux/reducers';

import type { ComponentProps } from 'react';

export const OrderInformationContainer = () => {
	const recipientEmailCheckErrorMessage = useAppSelector(state => state.orderInformation.recipientEmailCheckErrorMessage);
	const isProcessing = useAppSelector(state => state.orderInformation.isProcessing);
	const dispatch = useAppDispatch();

	const onUpdateField: TOrderInformationProps['onUpdateField'] = ({
		orderInformation,
		orderInformationIsValid
	}) => {
		dispatch(updateOrderInformation(orderInformation));
		dispatch(updateOrderInformationIsValid(orderInformationIsValid));
	};

	const onTypeRecipientEmail: TOrderInformationProps['onTypeRecipientEmail'] = (email) => {
		dispatch(giftPaymentCheckEmail({ email }));
	};

	const onChangeRecipientEmail = () => {
		dispatch(clearRecipientState());
	};

	return (
		<OrderInformation
			onUpdateField={onUpdateField}
			onTypeRecipientEmail={onTypeRecipientEmail}
			recipientEmailCheckErrorMessage={recipientEmailCheckErrorMessage}
			recipientEmailCheckProcessing={isProcessing}
			onChangeRecipientEmail={onChangeRecipientEmail}
		/>
	);
};

type TOrderInformationProps = ComponentProps<typeof OrderInformation>;
